// Generated by Framer (9bf39e5)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/xZndidUCt";

const enabledGestures = {CS2i_ofjW: {hover: true}};

const cycleOrder = ["CS2i_ofjW"];

const variantClassNames = {CS2i_ofjW: "framer-v-2wy6on"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const transformTemplate = (_, t) => `translate(-50%, -50%) ${t}`

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; title?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "CS2i_ofjW", title: mbYKXZBIj = "Referencia", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "CS2i_ofjW", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-Rdpr2", sharedStyle.className, classNames)} style={{display: "contents"}}>
<Link href={{webPageId: "Kz0oTMDWl"}} openInNewTab={false}><motion.a {...restProps} className={`${cx("framer-2wy6on", className)} framer-15ccat0`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"CS2i_ofjW"} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({"CS2i_ofjW-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-21ogod"} data-styles-preset={"xZndidUCt"} style={{"--framer-text-alignment": "center"}}>Referencia</motion.p></React.Fragment>} className={"framer-7zu6gw"} layoutDependency={layoutDependency} layoutId={"TauL5oQsO"} style={{"--framer-paragraph-spacing": "0px", textShadow: "none"}} text={mbYKXZBIj} transformTemplate={transformTemplate} transition={transition} variants={{"CS2i_ofjW-hover": {textShadow: "0px 0px 4px rgb(102, 120, 255)"}}} verticalAlignment={"top"} withExternalLayout {...addPropertyOverrides({"CS2i_ofjW-hover": {children: <React.Fragment><motion.p className={"framer-styles-preset-21ogod"} data-styles-preset={"xZndidUCt"}>Referencia</motion.p></React.Fragment>}}, baseVariant, gestureVariant)}/></motion.a></Link>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-Rdpr2 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Rdpr2 * { box-sizing: border-box; }", ".framer-Rdpr2 .framer-15ccat0 { display: block; }", ".framer-Rdpr2 .framer-2wy6on { height: 32px; overflow: visible; position: relative; text-decoration: none; width: 81px; }", ".framer-Rdpr2 .framer-7zu6gw { flex: none; height: auto; left: 49%; overflow: visible; position: absolute; top: 50%; white-space: pre; width: auto; }", ".framer-Rdpr2 .framer-v-2wy6on .framer-2wy6on { cursor: pointer; }", ".framer-Rdpr2.framer-v-2wy6on.hover .framer-2wy6on { overflow: hidden; }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 81
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"uEYtupJj9":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"mbYKXZBIj":"title"}
 */
const FramerHMw_UcYDX: React.ComponentType<Props> = withCSS(Component, css, "framer-Rdpr2") as typeof Component;
export default FramerHMw_UcYDX;

FramerHMw_UcYDX.displayName = "Referencia";

FramerHMw_UcYDX.defaultProps = {height: 32, width: 81};

addPropertyControls(FramerHMw_UcYDX, {mbYKXZBIj: {defaultValue: "Referencia", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerHMw_UcYDX, [...sharedStyle.fonts])